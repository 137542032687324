@use "./variables.module";

html,
body {
  padding: 0;
  margin: 0;
  height: 100% !important;
  -webkit-overflow-scrolling: touch !important;
  // overflow: auto !important;
  // background-color:variables.$std-production;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--wb-grey-75);
  border-radius: 3px;
}

.loading-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  background-color: #fcfcfc;
  align-items: center;
  justify-content: center;
  padding: var(--wb-spacing-xs);
  text-align: center;
}

.chatbot-container {
  width: 100%;
  height: 100vh;

  > wb-card {
    display: flex;
    flex-direction: column;
    border-radius: var(--wb-radius-l);
    height: 100%;
    border: none;

    .chatbot-header {
      border-top-right-radius: var(--wb-radius-l);
      border-top-left-radius: var(--wb-radius-l);
      background-color: var(--wb-black);
      padding: var(--wb-spacing-xxs) var(--wb-spacing-xs);
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > wb-heading {
          color: var(--wb-white);
          font-size: 22px;
        }
      }
    }
    .chatbot-dialog-container {
      flex: 1;
      background-color: #fcfcfc;
      padding: var(--wb-spacing-xxs) var(--wb-spacing-xs);
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 42px;

      .opposite-msg-container {
        display: flex;
        align-items: flex-end;

        &-text {
          margin-left: var(--wb-spacing-3xs);
          max-width: 80%;
          padding: var(--wb-spacing-xxs);
          background-color: var(--wb-blue-90);
          border-radius: var(--wb-radius-m);
          border-bottom-left-radius: 0;

          > wb-text {
            word-break: break-word;
          }
        }

        + .opposite-msg-container {
          margin-top: var(--wb-spacing-xxs);
        }
        + .own-msg-container-text {
          margin-top: var(--wb-spacing-xs);
        }

        &:not(.last) {
          > wb-icon {
            visibility: hidden;
          }

          .opposite-msg-container-text {
            border-bottom-left-radius: var(--wb-radius-m);
          }
        }
      }

      .own-msg-container-text {
        margin-left: auto;
        max-width: 80%;
        padding: var(--wb-spacing-xxs);
        background-color: var(--wb-black);
        border-radius: var(--wb-radius-m);
        border-bottom-right-radius: 0;
        width: fit-content;

        > wb-text {
          color: var(--wb-white);
          word-break: break-word;
        }

        + .own-msg-container-text {
          margin-top: var(--wb-spacing-xxs);
        }
        + .opposite-msg-container {
          margin-top: var(--wb-spacing-xs);
        }

        &:not(.last) {
          border-bottom-right-radius: var(--wb-radius-m);
        }
      }
    }

    .chatbot-footer {
      padding: var(--wb-spacing-3xs) var(--wb-spacing-xs);
      border-top: 1px solid var(--wb-grey-85);
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;

      &-msg-input {
        font-family: var(--wb-font-text);
        font-size: 14px;
        resize: none;
        width: 100%;
        height: 36px;
        padding: 8px 0;
        margin-right: var(--wb-spacing-xxs);
        border: none;
        overflow-y: auto;
        max-height: 100px;
        &:focus-visible {
          outline-width: 0;
        }
      }

      &-send-msg {
        width: 20px !important;
        height: 20px !important;
        margin-right: -2px;
      }

      .msg-sending-dots {
        position: absolute;
        left: 6px;
        top: -35px;
        width: 48px;
        height: 35px;
      }
    }

    &:focus-visible {
      outline-width: 0;
    }
  }
}

// .chat-fab-container {
//   background-color: var(--wb-black);
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   position: fixed;
//   bottom: 2rem;
//   right: 2rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .chat-fab-icon {
//     color: var(--wb-white);
//     --size: 32px;
//   }
// }
